import {Environment, RecordSource, Store, Network, Observable} from 'relay-runtime';
import {logInfo} from "./Logging";
import {v4} from "./uuid";
import {Platform} from 'react-native';

const log = false;

const isDev = __DEV__;

const isWeb = Platform.OS === 'web';
const isCanaryVersion = isWeb && window && (window.document.location.host === "v2-canary.cosmedcloud.com" || window.document.location.host === "localdev.cosmedcloud.com")

class Client {

    static v1Host() {
        if (isDev) {
            return "api-localdev.cosmedcloud.com:2083" //"clinic-canary.cosmedcloud.com";;
            
        } else if (isCanaryVersion) {
            return "clinic-canary.cosmedcloud.com";

        } else {
            return "clinic.cosmedcloud.com";

        }
    }

    static host = "";
    static accessToken = "";
    static subscriptionClient;
    static store = new Store(new RecordSource());

    static instanceID = v4();
    static currentClinic = "";
    static currentUser = 0;

    static authFailedSubscribers = [];

    static authFailedSubscribe(callback) {
        Client.authFailedSubscribers.push(callback)

        const index = Client.authFailedSubscribers.length - 1

        return {
            unsubscribe() {
                Client.authFailedSubscribers.splice(index, 1)
            }
        }
    }

    static setup(host, token) {
        Client.host = "https://" + host;
        Client.accessToken = token;

        logInfo("Setup environment", {
            API: host,
            v1Host: Client.v1Host()
        });

    }

    static subscribe(request, variables) {
        // const subscribeObservable = Client.subscriptionClient.request({
        //     query: request.text,
        //     operationName: request.name,
        //     variables,
        // });
        //
        // return Observable.from(subscribeObservable);
    }

    static fetchQuery(operation, variables) {
        if (log) {
            logInfo("GraphQL request", {
                name: operation.name,
                operationKind: operation.operationKind,
                text: operation.text,
                variables: variables
            });
        }

        return fetch(Client.host + '/graphql', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Client.accessToken
            },
            body: JSON.stringify({
                query: operation.text,
                variables,
            })
        }).then(response => {
            if (response.status === 401) {
                Client.authFailedSubscribers.forEach((callback) => {
                    callback();
                });
            }

            const jsonResponse = response.json();

            return jsonResponse;
        });
    }
}

export const environment = new Environment({
    network: Network.create(Client.fetchQuery, Client.subscribe),
    store: Client.store
});

export {Client};
