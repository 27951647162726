import React, {useEffect, useState} from 'react';
import {View} from "react-native"
import LottieControl from "./Lottie";

function Loading(props){

    const [show, setShow] = useState(props?.Show ?? false);
    const [width, setWidth] = useState(props?.Width ?? 100);
    const [height, setHeight] = useState(props?.Height ?? 150);
    const [loop, setLoop] = useState(props?.Loop ?? true);

    const [drawDelay, setDrawDelay] = useState(props?.Delay ?? 0);
    const [drawSpinner, setDrawSpinner] = useState(props.Delay > 0 ? false : true);

    useEffect(() => {
        if (drawDelay > 0){
            const timer = setTimeout(() => {
                setDrawSpinner(true);
              }, drawDelay);
              return () => clearTimeout(timer);
        }
    },[drawDelay]);

    if (!show){
        return props?.children ?? null;
    }

    if (!drawSpinner){
        return null;
    }

    return (
        <View style={[{alignItems: "center", flex: 1, justifyContent: "center"}, props.styles]}>
            <LottieControl 
                source={require("./Animations/big-loading.json")}
                autoPlay={true}
                style={{
                    width: width,
                    height: height
                }}
                loop={loop}
            />
        </View>
    )
}

export default Loading;