import {Client} from "./Relay_Enviroment";
import Oidc from "oidc-client";
import {v4} from "./uuid";
import {Platform} from "react-native";
import {VersionNumber} from "../VersionNumber";
import moment from "moment";
import { Hosts, AzureFunctionsHosts } from "./Hosts";

Oidc.Log.logger = console;
Oidc.Log.level = Oidc.Log.INFO;

const isDev = __DEV__;

const isWeb = Platform.OS === 'web';
const isCanaryVersion = isWeb && window && window.document.location.host === "v2-canary.cosmedcloud.com";

const v1TokenStoreName = "cmcv2tokenv1";

class AuthManager {

    static hostName() {
        if (isDev) return Hosts.LocalDev;
        if (isCanaryVersion) return Hosts.Canary;
        return Hosts.Production;
    }

    static azurefunctionsHostName(){
        if (isDev) return AzureFunctionsHosts.Canary;
        if (isCanaryVersion) return AzureFunctionsHosts.Canary;
        return AzureFunctionsHosts.Production;
    }

    static client;

    static deviceID;
    static deviceVersion;

    static async setup() {
        let version = await VersionNumber();

        AuthManager.deviceVersion = version;
        AuthManager.deviceID = window.localStorage["deviceID"];

        if (AuthManager.deviceID == null) {
            AuthManager.deviceID = v4();

            window.localStorage.setItem("deviceID", AuthManager.deviceID);
        }

        AuthManager.client = new Oidc.UserManager({
            authority: "https://" + AuthManager.hostName(),
            client_id: "app:webapp",
            popup_redirect_uri: "https://" + window.location.hostname + "/auth-callback",
            response_type: "code",
            scope: "cosmedcloud_api cosmedcloud_pii offline_access",
            automaticSilentRenew: true,
            silent_redirect_uri: "https://" + window.location.hostname + "/silent-renew",
            accessTokenExpiringNotificationTime: 40,
            post_logout_redirect_uri: "https://" + window.location.hostname,
            stateStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
            userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
            extraQueryParams: {
                appdevice: AuthManager.deviceID,
                appverion: AuthManager.deviceVersion
            },
            popupWindowFeatures: 'location=no,toolbar=no,width=600,height=600,left=100,top=100',
            metadata: {
                issuer: "https://graphql.cosmedcloud.com",
                authorization_endpoint: "https://" + AuthManager.hostName() + "/account/switchuser",
                token_endpoint: "https://" + AuthManager.hostName() + "/connect/token",
                userinfo_endpoint: "https://" + AuthManager.hostName() + "/connect/userinfo",
                end_session_endpoint: "https://" + AuthManager.hostName() + "/connect/endsession",
                jwks_uri: "https://" + AuthManager.hostName() + "/.well-known/openid-configuration/jwks"
            }
        });

    }

    static getTokenFromV1() {
        let query = window.location.search.substring(1);
        let vars = query.split('&');

        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split('=');

            if (decodeURIComponent(pair[0]) == "preview") {
                let token = decodeURIComponent(pair[1]);

                return token;
            }
        }

        let storedToken = window.localStorage[v1TokenStoreName];

        if (storedToken == null) {
            return null;
        }

        storedToken = JSON.parse(storedToken);

        const a = moment(storedToken.Date);
        const b = moment()

        const diffDays = b.diff(a, 'days');

        if (diffDays > 1) {
            delete window.localStorage["cmcv1token"];

            return null;
        }

        return storedToken.Token;
    }

    static setTokenFromV1(access_token) {
        if (window.localStorage[v1TokenStoreName] == null) {

            window.localStorage[v1TokenStoreName] = JSON.stringify({
                Token: access_token,
                Date: moment()
            });
        }


        Client.setup(AuthManager.hostName(), access_token);
    }

    static async silentAuthSignin() {
        try {
            let hasSession = window.localStorage["cmcsession"] != null && window.localStorage["cmcsession_expires"] != null;

            if (!hasSession) {
                return  {
                    OK: false
                };
            }

            let now = moment();
            let sessionExpiry = moment(window.localStorage["cmcsession_expires"]);

            if (now.diff(sessionExpiry, 'second') > 0) {
                return  {
                    OK: false
                };
            }

            let result = await AuthManager.client.signinSilent();

            if (result != null) {
                Client.setup(AuthManager.hostName(), result.access_token);

                return {
                    OK: true
                };
            }

        } catch (e) {
            return {
                OK: false
            };
        }

        return {
            OK: false
        };
    }

    static async authSignout(providerSignout) {
        delete window.localStorage["cmcsession"];

        delete window.localStorage[v1TokenStoreName];

        if (AuthManager.client) {
            if (providerSignout) {
                await AuthManager.client.signoutRedirect();

            } else {
                await AuthManager.client.removeUser();

            }
        }


    }

    static async authSignin() {
        try {
            let result = await AuthManager.client.signinPopup();

            if (result != null) {
                Client.setup(AuthManager.hostName(), result.access_token);

                window.localStorage["cmcsession"] = "true";
                //this is updated by the notifications fetch every minute
                window.localStorage["cmcsession_expires"] = moment().add(2, "hour");

                return {
                    OK: true
                };

            } else {
                return {
                    OK: false
                };

            }

        } catch (error) {
            console.log(error);

            return {
                OK: false
            };
        }
    }
}


export {AuthManager};
