export const Hosts = {
    Production: "graphql.cosmedcloud.com",
    Canary: "graphql-canary.cosmedcloud.com",
    LocalDev: "localdev.cosmedcloud.com:22499",
    NateDev: "natedev-proxy.cosmedcloud.com",
    MaxDev: "maxdev-proxy.cosmedcloud.com",
    MaxDevVm: "maxdev-vm-proxy.cosmedcloud.com",
    MaxDevVmIp: "10.0.4.18",
    LukeDev: "lukedev-proxy.cosmedcloud.com",
    TomDev: "tomdev-proxy.cosmedcloud.com"
}

export const AzureFunctionsHosts = {
    Production: "afapi.cosmedcloud.com",
    Canary: "afapi-canary.cosmedcloud.com",
    MaxDevVm: "maxdev-vm-proxy.cosmedcloud.com"
}