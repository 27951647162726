import {logInfo} from "./Logging";
import LogRocket from 'logrocket';

class Analytics {
    static Client;

    static trackPageView(name) {
        if (Analytics.Client) {
            Analytics.Client.trackPageView(name);

            logInfo("Analytics - Pageview", {
                Event: name
            });
        }


    }

    static trackError(error) {

    }

    static trackEvent(event, props) {
        if (Analytics.Client) {
            Analytics.Client.trackEvent({
                name: event,
                properties: props
            });

            logInfo("Analytics - Event", {
                Event: event,
                Props: props
            });
        }

    }

    static identifyUser(user, account) {
        let userID = user.id.split(':')[1];
        let accountID = account.id.split(':')[1];

        if (Analytics.Client) {

            Analytics.Client.clearAuthenticatedUserContext();

            Analytics.Client.setAuthenticatedUserContext(userID, accountID, true);

            logInfo("Setup analytics", {User: userID, Account: accountID});
        }

        if (LogRocket) {
            try {
                LogRocket.identify(userID, {
                    name: user.name,
                    account: accountID
                });
            } catch (e) {

            }
        }

    }

}


export {Analytics};
