import React , {Component, Suspense} from 'react';
import { Image, Text, Linking, TouchableOpacity, View } from 'react-native';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {AuthManager} from "./GraphQL/Common/AuthManager";
import Oidc from "oidc-client";
import { hot } from 'react-hot-loader';
import Loading from "./GraphQL/Common/UI/Loading";
import {FeatureFlags} from "./GraphQL/Common/FeatureFlags";
import {Analytics} from "./GraphQL/Common/Analytics";
import {VersionNumber} from "./GraphQL/VersionNumber";
import Icons from "react-native-vector-icons/FontAwesome";
import LogRocket from 'logrocket';

const ClientVirtualConsultWeb = React.lazy(() => import("./GraphQL/VirtualConsult/ClientVirtualConsult"));
const AppWrapper = React.lazy(() => import("./GraphQL/AppWrapper"));
const MobileEntryWrapper = React.lazy(() => import("./GraphQL/VirtualConsult/MobileEntryWrapper"));
const MobileChannelWeb = React.lazy(() => import("./GraphQL/Chat/Channel/MobileChannelWeb"));
const ClientInsights = React.lazy(() => import("./GraphQL/Client/Common/RelayWrapper"));


if (!__DEV__) {
    

    LogRocket.init('qugfvw/cosmedcloud', {
        console: {
            isEnabled: {
                log: false,
                info: false,
                debug: false,
                warn: false,
                error: true
            }
        },
        mergeIframes: true,
        rootHostname: 'cosmedcloud.com'
    });


    Analytics.Client = new ApplicationInsights({
        config: {
            disableFetchTracking: false,
            enableAutoRouteTracking: true,
            disableExceptionTracking: true,
            enableCorsCorrelation: true,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true,
            correlationHeaderExcludedDomains: [
                'events.launchdarkly.com',
                'cdn.lr-ingest.io',
                'r.lr-ingest.io',
                'app.launchdarkly.com',
                'browser.pipe.aria.microsoft.com'
            ],
            instrumentationKey: 'e65f05ac-db22-4e6d-912b-4ff48d4cfeb8'
        }
    });

    Analytics.Client.loadAppInsights();

    Analytics.Client.trackPageView();

} 

const ErrorBoundary = class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        logErrorToMyService(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
};



class WebApp extends Component {
    constructor(props) {
        super(props);

        const isSupportedBrowser = true;//browser !== null && browser.name === "chrome";

        this.state = {
            Auth_Callback: window.location.pathname == "/auth-callback",
            Setup: false,
            Verison: "",
            Location_Error: false,
            Location_Long: null,
            Location_Lat: null,
            LocationObtaining: false,
            Loading: isSupportedBrowser,
            Browser_Supported: isSupportedBrowser
        };

        this.setup = this.setup.bind(this);
        this.auth = this.auth.bind(this);
        this.reAuth = this.reAuth.bind(this);
        this.getVersion = this.getVersion.bind(this);
    }

    getLocation() {
        this.setState({
            LocationObtaining: true,
            Auth_Callback: false,
            Loading: false
        });
        
        if (!__DEV__) {
            this.gotLocation(0, 0, false);
            return;
        }
        navigator.geolocation.getCurrentPosition(
            (location) => this.gotLocation(location.coords.longitude.toString(), location.coords.latitude.toString()),
            () => this.gotLocation(0, 0, true),
            {
                enableHighAccuracy: true,
                timeout: 15000,
                maximumAge: 0
            }
        );
    }

    gotLocation(longitude, latitude, error) {
        this.setState({
            LocationObtaining: false,
            Auth_Callback: false,
            Loading: false,
            Setup: true,
            Location_Error: error,
            Location_Long: longitude,
            Location_Lat: latitude
        });
    }


    async getVersion() {
        let version = await VersionNumber();

        this.setState({
            Version: version
        });
    }

    async componentDidMount() {
        if (this.state.Browser_Supported) {
            await FeatureFlags.setup();

            await this.getVersion();

            await this.setup();
        }
    }

    reAuth() {
        document.location.reload();

    }

    async setup() {
        await AuthManager.setup();

        const silentAuth = await AuthManager.silentAuthSignin();

        if (silentAuth.OK) {
            this.getLocation();

        } else if (this.state.Auth_Callback) {
            AuthManager.client.signinPopupCallback().catch(function (err) {
                Oidc.Log.logger.error("error: " + err && err.message);
            });

        } else {
            this.setState({
                Loading: false
            });

        }
    }

    async auth() {
        this.setState({
            Loading: true
        });

        const response = await AuthManager.authSignin();

        if (response.OK) {
            this.getLocation();

        } else {
            this.setState({
                Loading: false
            });

        }
    }


    render() {


        if (this.state.Setup) {
            return (
                <Suspense fallback={<Loading Show={true}/>}>
                    <View style={{
                        flex: 1
                    }}>
                        {this.state.Location_Error ?
                            <TouchableOpacity onPress={() => this.setState({Location_Error: false})} style={{
                                backgroundColor: "red"
                            }}>
                                <Text style={{
                                    color: "white",
                                    fontSize: 11,
                                    textAlign: "center",
                                    padding: 10
                                }}>An error occurred obtaining your location or you didnt provide permission - you are
                                    currently receiving out of clinic permissions. Enable location permissions to use in
                                    clinic permissions if applicable. <Icons name={"close"}/></Text>
                            </TouchableOpacity> : null}

                        <AppWrapper App={"clinic"}
                                    Location_Long={this.state.Location_Long}
                                    Location_Lat={this.state.Location_Lat}
                                    fnAppReAuth={this.reAuth}/>
                    </View>
                </Suspense>
            )
        }

        let authContainer;

        let locationPrompt;

        if (this.state.Auth_Callback) {
            authContainer = (
                <View style={{flex: 1}}>
                    <Loading Show={true}/>
                </View>
            );

        } else if (this.state.LocationObtaining) {
            authContainer = (
                <View style={{flex: 1, alignItems: "center", justifyContent: "center"}}>

                    <Loading Show={true}/>
                </View>
            );

            locationPrompt = [
                <View key={"bg"} style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, .4)"
                }}>

                </View>,

                <View key={"prompt"} style={{
                    position: "absolute",
                    left: 130,
                    width: 460,
                    padding: 20,
                    backgroundColor: "white",
                    top: 20,
                    borderBottomRightRadius: 20
                }}>

                    <Image resizeMode="cover" style={{
                        width: 150,
                        height: 150
                    }} source={require("./Arrow.png")}/>

                    <View style={{
                        left: 130
                    }}>
                        <Text style={{
                            fontWeight: "bold"
                        }}>Allow location permission if prompted</Text>
                    </View>

                </View>
            ]

        } else if (this.state.Loading) {
            authContainer = (
                <View style={{flex: 1}}>

                    <Loading Show={true}/>
                </View>
            );

        } else if (this.state.Browser_Supported) {
            authContainer = (
                <View>

                    <Image resizeMode="contain" style={{
                        width: "100%",
                        marginBottom: 100,
                        height: 300
                    }} source={require("./landingtrans.png")}/>

                    <TouchableOpacity style={{
                        backgroundColor: "#0070c9",
                        padding: 20,
                        borderRadius: 10
                    }} onPress={this.auth}>
                        <Text style={{
                            textAlign: "center",
                            color: "white",
                            fontSize: 18
                        }}><Icons size={20} name={"key"}/>{" Sign In"}</Text>
                    </TouchableOpacity>


                    <Text style={{
                        color: "grey",
                        textAlign: "center",
                        marginTop: 10,
                        marginBottom: 30,
                        marginHorizontal: 20,
                        fontSize: 13
                    }}><Icons name={"location-arrow"}/> Upon sign-in, we will ask permission to obtain your device's
                        location. This is to protect your clinic's data & provide you with the correct permissions based
                        on your location. If you cannot grant your permissions you will be offered out of clinic
                        permissions.</Text>


                </View>
            )

        } else {
            authContainer = (
                <Text style={{
                    fontSize: 14,
                    fontWeight: "bold",
                    textAlign: "center"
                }}>Sorry, your browser is unsupported. Please use cosmedcloud with Chrome</Text>
            )
        }

        return (
            <ErrorBoundary>
                <Suspense fallback={<Loading Show={true}/>}>

                    <View style={{
                        flex: 1,
                        flexDirection: "row",
                        backgroundColor: 'whitesmoke'
                    }}>


                        <View style={{
                            backgroundColor: "#eff2f5",
                            flex: 1,
                            justifyContent: 'center',
                            transform: __DEV__ ? [{rotate: '20deg'}] : undefined,
                            borderRadius: __DEV__ ? 99999 : undefined,
                        }}>
                            <View style={{
                                flex: 1,
                                justifyContent: 'center',
                            }}>


                                <View style={{
                                    flexDirection: "row",
                                    paddingHorizontal: 50,
                                    alignItems: 'center'
                                }}>
                                    <View style={{
                                        alignItems: 'center'
                                    }}>
                                        <Image resizeMode="cover" style={{
                                            width: 80,
                                            height: 80
                                        }} source={require("./LogoTrans.png")}/>
                                    </View>
                                    <View style={{
                                        marginLeft: 20,
                                    }}>

                                        <Text style={{

                                            fontSize: 30
                                        }}>cosmedcloud ™</Text>

                                        <Text style={{
                                            fontSize: 19,
                                            flex: 1,
                                            marginTop: 5,
                                            lineHeight: 20,
                                            color: "grey"
                                        }}>Cloud-based clinic management</Text>

                                        <View style={{
                                            marginTop: 15,
                                            flexDirection: "row"
                                        }}>

                                            <Text style={{
                                                color: "grey",
                                                textAlign: "left",
                                                borderRightWidth: 1,
                                                borderRightColor: "grey",
                                                marginRight: 10,
                                                paddingRight: 10,
                                                fontSize: 12
                                            }}>v{this.state.Version}</Text>


                                            <TouchableOpacity style={{
                                                borderRightWidth: 1,
                                                borderRightColor: "grey",
                                                marginRight: 10,
                                                paddingRight: 10,
                                            }}
                                                              onPress={() => Linking.openURL("https://www.cosmedcloud.com/privacypolicy")}>
                                                <Text style={{
                                                    color: "grey",
                                                    textAlign: "left",
                                                    fontSize: 12
                                                }}>
                                                    Privacy Policy
                                                </Text>
                                            </TouchableOpacity>

                                            <TouchableOpacity
                                                onPress={() => Linking.openURL("https://www.cosmedcloud.com/termsofuse")}>
                                                <Text style={{
                                                    color: "grey",
                                                    textAlign: "left",
                                                    fontSize: 12
                                                }}>
                                                    Terms Of Use
                                                </Text>
                                            </TouchableOpacity>

                                        </View>


                                    </View>


                                </View>


                            </View>


                        </View>

                        <View style={{
                            flex: 1,
                            justifyContent: 'center',
                            backgroundColor: "white",
                            padding: 100
                        }}>

                            {authContainer}

                        </View>

                        {locationPrompt}

                    </View>
                </Suspense>
            </ErrorBoundary>
        );
    }
}

function WebAppRouter(props) {
    if (document.location.pathname === "/v1/insights") {

        return (
            <Suspense fallback={<View></View>}>
                <ClientInsights {...props}/>
            </Suspense>
        );


    } else if (document.location.pathname === "/virtual-consult") {
        return (
            <Suspense fallback={<View></View>}>
                <ClientVirtualConsultWeb {...props}/>
            </Suspense>
        );

    } else if (document.location.pathname === "/virtual-consult-start-mobile") {
        return (
            <Suspense fallback={<View></View>}>
                <MobileEntryWrapper {...props}/>
            </Suspense>
        );

    }  else if (document.location.pathname === "/chat-channel-mobile") {
        return (
            <Suspense fallback={<View></View>}>
                <MobileChannelWeb {... props}/>
            </Suspense>
        );

    } else {
        return <WebApp {...props}/>;

    }
}

export default hot(module)(WebAppRouter);
