import React, {Component} from 'react';
import {ScrollView, Image, Geolocation, TouchableOpacity, Text, View} from 'react-native';
import Lottie from 'react-lottie';

class LottieControl extends Component {

    render() {
        const defaultOptions = {
            loop: this.props.loop,
            autoplay: this.props.autoPlay,
            animationData: this.props.source,
            speed: this.props.speed
        };

        return (
            <Lottie options={defaultOptions}
                    height={this.props.style.height}
                    width={this.props.style.width}
                    resizeMode="cover"
            />
        )
    }
}

export default LottieControl;

