import * as LDClient from "launchdarkly-js-client-sdk";

import {logInfo} from "./Logging";

class FeatureFlags {

    static Client;

    static Subscribers = [];

    static Flags = {};

    static onChange(callback) {
        FeatureFlags.Subscribers.push(callback);
    }

    static async setup() {
        FeatureFlags.Client = LDClient.initialize("5ed825213f22010a110cb09a", {
            anonymous: true,
            key: ""
        });

        FeatureFlags.Client.on('ready', function() {
            FeatureFlags.loadFlags();

            logInfo("Feature flags ready");
        });

        FeatureFlags.Client.on('change', function() {
            logInfo("Feature flags changes");

            FeatureFlags.loadFlags();
        });

    }

    static loadFlags() {
        FeatureFlags.Flags = FeatureFlags.Client.allFlags();

        FeatureFlags.Subscribers.forEach(a => a(FeatureFlags.Flags));

        logInfo("Updated feature flags", FeatureFlags.Flags);
     }

    static async identifyUser(user, account) {
        let thisUser = {
            key: user.id.split(':')[1],
            name: user.name,
            email: user.email,
            custom: {
                account: account.name,
                type: user.type
            }
        };

        await FeatureFlags.Client.identify(thisUser, null);

        logInfo("Setup user for feature flags", thisUser);
    }

}


export {FeatureFlags};
